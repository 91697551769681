import {URL} from "../constants/API";
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {logout, setTokens} from "../slices/authSlice";
import {isFilled} from "../utils/generalHelper";

const config = {
    apiUrl: URL.concat('/api/')
}

const baseQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
});

const baseAuthenticatedQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.token

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
});

const baseQueryWithReauth = async (
    args,
    api,
    extraOptions,
) => {
    let result = await baseAuthenticatedQuery(args, api, extraOptions);
    let token = JSON.parse(localStorage.getItem('tokens'))

    if (args?.url === 'login_check') {
        return result
    }

    if (result.error && result.error.status === 401 && isFilled(token?.refresh_token)) {
        const refreshResult = await baseQuery({
            url: 'token/refresh',
            method: 'POST',
            headers: {},
            body: {refresh_token: token?.refresh_token},
        }, api, extraOptions);

        if (refreshResult.data) {
            api.dispatch(setTokens(refreshResult.data));

            // retry the initial query
            result = await baseAuthenticatedQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const baseApi = createApi({
        baseQuery: baseQueryWithReauth,
        tagTypes: [
            'PredefinedTasks',
            'Files',
            'LinkedFiles',
            'FileTypes',
            'EmailUpload',
            'EmailUploadAddresses',
            'Tasks',
            'CompanyDependent',
            'CurrentUser',
            'AdminCompanies',
            'AdminSubscription',
            'AdminUsers',
            'Subscription',
            'Usage',
            'AgencyClients',
            'UploadHistory',
            'UploadHistoryFile',
        ],
        endpoints: () => ({}),
    }
)
