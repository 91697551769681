import React, {useEffect, useMemo} from 'react'
import {
    Box,
    Flex,
    Table,
    TableContainer,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import {
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    VisibilityState,
    OnChangeFn,
    SortingState,
    ColumnSizingState,
} from '@tanstack/react-table'
import {IoArrowDown, IoArrowUp, IoSwapVertical} from "react-icons/io5";
import {
    loadFromLocalStorage,
    saveColumnWidths,
    selectColumnWidths
} from "../../slices/tableColumnWidthsSlice";
import {useAppDispatch} from "../../hooks/store";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {isDevelopment} from "../../utils/generalHelper";
import {MemoizedTableBody} from "./MemoizedTableBody";
import TableBody from "./TableBody";


export default function TableWrapper<T>(
    {
        data,
        columns,
        sorting = undefined,
        setSorting = undefined,
        columnVisibility,
        setColumnVisibility,
        enableSorting = false,
        tableId,
        noDataMessage = null,
    }: {
        data: T[],
        columns: ColumnDef<any>[],
        tableId: string,
        sorting?: any,
        setSorting?: any,
        enableSorting?: boolean
        columnVisibility: VisibilityState,
        setColumnVisibility: any,
        noDataMessage?: string | null
    }) {
    const dispatch = useAppDispatch()
    const columnWidths = useSelector((state: RootState) => selectColumnWidths(state, tableId))

    useEffect(() => {
        dispatch(loadFromLocalStorage())
    }, [dispatch])

    const handleSortingChange: OnChangeFn<SortingState> = (newSorting) => {
        dispatch(setSorting(newSorting));
    };

    const handleColumnSizingChange: OnChangeFn<ColumnSizingState> = (updaterOrValue) => {
        const newColumnSizing = typeof updaterOrValue === 'function'
            ? updaterOrValue(columnWidths as ColumnSizingState)
            : updaterOrValue
        dispatch(saveColumnWidths({tableId, widths: newColumnSizing}))
    }

    const getDefaultColumnSizing = () => {
        return columns.reduce((acc, column) => {
            if (column.id && typeof column.size === 'number') {
                acc[column.id] = column.size;
            }
            return acc;
        }, {} as ColumnSizingState);
    };

    const table = useReactTable({
        data: data,
        columns,
        defaultColumn: {
            minSize: 20,
            maxSize: 800,
        },
        state: {
            columnVisibility,
            sorting,
            columnSizing: Object.keys(columnWidths).length > 0 ? columnWidths : getDefaultColumnSizing(),
        },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: handleSortingChange,
        onColumnSizingChange: handleColumnSizingChange,
        columnResizeMode: 'onChange',
        manualSorting: true,
        enableSorting: enableSorting,
        getCoreRowModel: getCoreRowModel(),
        debugTable: isDevelopment,
        debugHeaders: isDevelopment,
        debugColumns: isDevelopment,
    })

    /**
     * Instead of calling `column.getSize()` on every render for every header
     * and especially every data cell (very expensive),
     * we will calculate all column sizes at once at the root table level in a useMemo
     * and pass the column sizes down as CSS variables to the <table> element.
     */
    const columnSizeVars = useMemo(() => {
        const headers = table.getFlatHeaders()
        const colSizes: { [key: string]: number } = {}
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i]!
            colSizes[`--header-${header.id}-size`] = header.getSize()
            colSizes[`--col-${header.column.id}-size`] = header.column.getSize()
        }
        return colSizes
    }, [table.getState().columnSizingInfo, table.getState().columnSizing])

    return (
        <Box
            bg={"white"}
            rounded={"2xl"}
            border={"1px primary var(--chakra-colors-gray-200)"}
            mt={"6"}
            style={columnSizeVars}
        >
            {/*<div className="px-1 border-b border-black">*/}
            {/*    <label>*/}
            {/*        <input*/}
            {/*            {...{*/}
            {/*                type: 'checkbox',*/}
            {/*                checked: table.getIsAllColumnsVisible(),*/}
            {/*                onChange: table.getToggleAllColumnsVisibilityHandler(),*/}
            {/*            }}*/}
            {/*        />{' '}*/}
            {/*        Toggle All*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*{table.getAllLeafColumns().map(column => {*/}
            {/*    return (*/}
            {/*        <div key={column.id} className="px-1">*/}
            {/*            <label>*/}
            {/*                <input*/}
            {/*                    {...{*/}
            {/*                        type: 'checkbox',*/}
            {/*                        checked: column.getIsVisible(),*/}
            {/*                        onChange: column.getToggleVisibilityHandler(),*/}
            {/*                    }}*/}
            {/*                />{' '}*/}
            {/*                {column.id}*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*})}*/}
            {/*<DisplayTableState table={ table}/>*/}
            <TableContainer w={"full"} overflowX="auto">
                <Table variant="striped" minW={"100%"} style={{ width: table.getTotalSize(), tableLayout: 'fixed' }}>
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id} sx={{th: {color: "black", textAlign: "center"}}}>
                                {headerGroup.headers.map(header => (
                                    <Th key={header.id}
                                        position={'relative'}
                                        sx={{
                                            width: `calc(var(--header-${header?.id}-size) * 1px)`,
                                            cursor: header.column.getCanSort() ? 'pointer' : 'default',
                                        }}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : <Flex justify="center" align={'center'} gap={"2px"}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getCanSort() && header.column.getIsSorted() === false
                                                    ? <IoSwapVertical/>
                                                    : header.column.getIsSorted() !== false ? (
                                                        {
                                                            asc: <IoArrowUp/>,
                                                            desc: <IoArrowDown/>,
                                                        }[header.column.getIsSorted() as string] ?? null
                                                    ) : null}
                                            </Flex>
                                        }
                                        {header.column.getCanResize() && (
                                            <Box
                                                position="absolute"
                                                top={0}
                                                bgColor={header.column.getIsResizing() ? 'red.400' : 'teal.400'}
                                                rounded="sm"
                                                right={0}
                                                bottom={0}
                                                width="2px"
                                                cursor="col-resize"
                                                userSelect="none"
                                                onDoubleClick={() => header.column.resetSize()}
                                                onMouseDown={header.getResizeHandler()}
                                                onTouchStart={header.getResizeHandler()}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    {/* When resizing any column we will render this special memoized version of our table body */}
                    {table.getState().columnSizingInfo.isResizingColumn ? (
                        <MemoizedTableBody table={table} noDataMessage={noDataMessage}/>
                    ) : (
                        <TableBody table={table} noDataMessage={noDataMessage}/>
                    )}
                </Table>
            </TableContainer>
        </Box>
    )
}
