import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import {isFilled} from "../../utils/generalHelper";
import {Button, Center, Flex, Td,} from "@chakra-ui/react";
import {
    ColumnDef,
    VisibilityState
} from "@tanstack/react-table";
import {CellTypes, TableId, UploadHistory} from "../../types";
import {AiFillEye} from "react-icons/ai";
import {IoDocumentText, IoPencil} from "react-icons/io5";
import {showUploadHistoryModal} from "../../slices/uploadHistoryViewModalSlice";
import {fileSourceIcon} from "../../utils/generalHelperElements";
import TableWrapper from "../Table/TableWrapper";
import TableCell from "../Table/TableCell";

export default function UploadHistoryTable(
    {
        uploadHistory = [],
        hiddenFields = [],
        // inModal = false,
        customAction = undefined,
        customActionArgs = []
    }: {
        uploadHistory: UploadHistory[],
        hiddenFields?: string[],
        // inModal?: boolean,
        customAction?: (info: any, ...args: any[]) => void,
        // customActionArgs?: any[]
        customActionArgs?: any
    }) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    // const sorting = useSelector(selectFileRawSorting)

    const columns = useMemo<ColumnDef<UploadHistory>[]>(() => [
        {
            id: 'createdBy',
            header: t('common.createdBy'),
            enableSorting: false,
            accessorFn: row => `${row.user.first_name} ${row.user.last_name}`,
            cell: info => (
                <TableCell
                    content={info.row.original.user.first_name}
                    subContent={info.row.original.user.last_name}
                    isContentFilled={isFilled(info.row.original.user.first_name)}
                    type={CellTypes.Name}
                />
            ),
        },
        {
            id: 'note',
            header: t('common.note'),
            accessorKey: 'note',
            enableSorting: false,
            cell: info => (
                <TableCell
                    content={info.getValue() as string}
                    isContentFilled={true}
                />
            ),
        },
        {
            id: 'source',
            header: t('fileSources.source'),
            accessorKey: 'source',
            enableSorting: false,
            cell: info => (
                <Center gap={2}>
                    {fileSourceIcon(info.getValue() as string)}
                </Center>
            ),
        },
        {
            id: 'files',
            header: t('files.file', {count: 2}),
            accessorFn: row => row.files.length ?? 0,
            cell: info => (
                <Center gap={2}>
                    <IoDocumentText
                        fontSize={20}
                        color={`var(--chakra-colors-teal-300)`}
                    />
                    {`${info.row.original.files.length ?? 0}`}
                </Center>
            ),
            enableSorting: false,
        },
        {
            id: 'createdAt',
            header: t('files.uploadedAt'),
            accessorKey: 'created_at',
            enableSorting: false,
            cell: info => (
                <TableCell
                    content={info.getValue() as string}
                    isContentFilled={isFilled(info.getValue() as string)}
                    type={CellTypes.Date}
                />
            ),
        },
        {
            id: 'actions',
            enableSorting: false,
            meta: {
                customTd: true,
            },
            cell: info => customAction
                ? customAction(info, ...customActionArgs)
                : (
                    <Td px={0}
                        bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                        <Flex justifyContent="space-around">
                            <Button
                                height={'25px'}
                                pe={0}
                                me={0}
                                colorScheme={'white'}
                                onClick={() => navigate(`/upload/${info.row.original.id}`)}
                            >
                                <IoPencil
                                    fontSize={25}
                                    color="var(--chakra-colors-teal-400)"
                                />
                            </Button>
                            <Button
                                height={'25px'}
                                ps={0}
                                colorScheme={'white'}
                                onClick={() => dispatch(showUploadHistoryModal({uploadHistory: info.row.original}))}
                            >
                                <AiFillEye
                                    fontSize={25}
                                    color="var(--chakra-colors-teal-400)"
                                />
                            </Button>
                        </Flex>
                    </Td>
                ),
        },
    ], [t, customAction, customActionArgs, navigate, dispatch]);

    const defaultVisibility = {};

    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(() => {
        return {...defaultVisibility, ...hiddenFields.reduce((acc, field) => ({...acc, [field]: false}), {})};
    });

    return (
        <TableWrapper
            data={uploadHistory}
            columns={columns}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            tableId={TableId.UploadHistory}
            noDataMessage={'uploadHistory.noUploadSesions'}
        />
        // <TableWrapper table={table} noDataMessage={'uploadHistory.noUploadSesions'}/>
    );
};
