import {Action, configureStore, Middleware} from '@reduxjs/toolkit';
import {setupListeners} from "@reduxjs/toolkit/query";
import {baseApi} from "../services/apiService";
import authSlice from "../slices/authSlice";
import {rtkQueryLogger} from "../middleware/rtkQueryLogger";
import devSlice from "../slices/dev/devSlice";
import FilePreviewModalSlice from "../slices/filePreviewModalSlice";
import FileViewModalSlice from "../slices/fileViewModalSlice";
import TaskViewModalSlice from "../slices/taskViewModalSlice";
import toastSlice from "../slices/toastSlice";
import FileFilterModalSlice from "../slices/fileFilterModalSlice";
import FileSortModalSlice from "../slices/fileSortModalSlice";
import TaskSortModalSlice from "../slices/taskSortModalSlice";
import TaskFilterSlice from "../slices/taskFilterSlice";
import LinkedFileSortModalSlice from "../slices/linkedFileSortModalSlice";
import UploadHistoryViewModalSlice from "../slices/uploadHistoryViewModalSlice";
import {rtkQueryNotifications} from "../middleware/rtkQueryNotifications";
import TableColumnWidthsSlice from "../slices/tableColumnWidthsSlice";

export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        auth: authSlice,
        [baseApi.reducerPath]: baseApi.reducer,
        filePreviewModal: FilePreviewModalSlice,
        fileViewModal: FileViewModalSlice,
        fileFilterModal: FileFilterModalSlice,
        fileSortModal: FileSortModalSlice,
        linkedFileSortModal: LinkedFileSortModalSlice,
        taskViewModal: TaskViewModalSlice,
        taskFilter: TaskFilterSlice,
        taskSortModal: TaskSortModalSlice,
        uploadHistoryViewModal: UploadHistoryViewModalSlice,
        tableColumnWidths: TableColumnWidthsSlice,
        dev: devSlice,
        toast: toastSlice,
    },

    // todo add later
    // https://www.npmjs.com/package/redux-logger#log-only-in-development

    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(
                baseApi.middleware,
                rtkQueryNotifications as Middleware<
                    (action: Action<'specialAction'>) => number
                >,
                rtkQueryLogger as Middleware<
                    (action: Action<'specialAction'>) => number
                >,
            ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
