import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store/store";

export interface TableColumnWidths {
    [tableId: string]: {
        [columnId: string]: number
    }
}

export interface TableColumnWidthsState {
    widths: TableColumnWidths
}

const LOCAL_STORAGE_KEY = 'tableColumnWidths'

const initialState: TableColumnWidthsState = {
    widths: {},
}

const tableColumnWidthsSlice = createSlice({
    name: 'tableColumnWidths',
    initialState,
    reducers: {
        loadFromLocalStorage: (state) => {
            try {
                const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY)
                if (serializedState) {
                    state.widths = JSON.parse(serializedState)
                }
            } catch (err) {
                console.error('Could not load state from localStorage:', err)
            }
        },
        saveColumnWidths: (state, action: PayloadAction<{
            tableId: string;
            widths: { [columnId: string]: number }
        }>) => {
            const {tableId, widths} = action.payload
            state.widths[tableId] = widths
            try {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.widths))
            } catch (err) {
                console.error('Could not save state to localStorage:', err)
            }
        },
        resetColumnWidths: (state, action: PayloadAction<{ tableId: string }>) => {
            const {tableId} = action.payload
            delete state.widths[tableId]
            try {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.widths))
            } catch (err) {
                console.error('Could not save state to localStorage:', err)
            }
        },
        // resetColumnWidths: (state, action: PayloadAction<{ tableId: string; defaultWidths: { [columnId: string]: number } }>) => {
        //     const { tableId, defaultWidths } = action.payload
        //     state.widths[tableId] = defaultWidths
        //     try {
        //         localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.widths))
        //     } catch (err) {
        //         console.error('Could not save state to localStorage:', err)
        //     }
        // },
        clearAllColumnWidths: (state) => {
            state.widths = {}
            localStorage.removeItem(LOCAL_STORAGE_KEY)
        },
    },
})

export const {
    loadFromLocalStorage,
    saveColumnWidths,
    resetColumnWidths,
    clearAllColumnWidths
} = tableColumnWidthsSlice.actions

export const selectColumnWidths = (state: RootState, tableId: string) =>
    state.tableColumnWidths.widths[tableId] || {}

export default tableColumnWidthsSlice.reducer
