import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import {useLazyGetDownloadFileQuery} from "../../services/fileService";
import {isFilled, isNoAccessFile} from "../../utils/generalHelper";
import {Button, Center, Flex, Td} from "@chakra-ui/react";
import {
    ColumnDef,
    VisibilityState
} from "@tanstack/react-table";
import {BsFileEarmarkCheckFill, BsFillCloudDownloadFill} from "react-icons/bs";
import {getTasksCountColor} from "../../utils/taskHelper";
import {CellTypes, File, NoAccessFile, TableId, TaskStatus} from "../../types";
import {closeFileViewModal, showFileViewModal} from "../../slices/fileViewModalSlice";
import {AiFillEye} from "react-icons/ai";
import TableWrapper from "../Table/TableWrapper";
import {selectFileRawSorting, setSorting} from "../../slices/fileSortModalSlice";
import TableCell from "../Table/TableCell";

export default function FilesTable(
    {
        files = [],
        hiddenFields = [],
        inModal = false,
        customAction = undefined,
        customActionArgs = []
    }: {
        files: (File | NoAccessFile)[],
        hiddenFields?: string[],
        inModal?: boolean,
        customAction?: (info: any, ...args: any[]) => void,
        // customActionArgs?: any[]
        customActionArgs?: any
    }) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [trigger, {isLoading: isLoadingTrigger}] = useLazyGetDownloadFileQuery()
    const sorting = useSelector(selectFileRawSorting)

    const columns = useMemo<ColumnDef<File | NoAccessFile>[]>(() => [
        {
            id: 'ids',
            header: t('common.ID'),
            accessorFn: row => `${row.internal_id} ${row.external_id}`,
            cell: info => (
                <TableCell
                    content={info.row.original.internal_id}
                    subContent={info.row.original.external_id}
                    placeholder={info.row.original.id}
                    isContentFilled={isFilled(info.row.original.internal_id) || isFilled(info.row.original.external_id)}
                    type={CellTypes.CombinedIds}
                    maxLength={11}
                />
            ),
            enableSorting: true,
        },
        {
            id: 'name',
            header: t('files.fileName'),
            accessorFn: row => row.name ?? row.original_name,
            cell: info => isNoAccessFile(info.row.original)
                ? <TableCell
                    content={t('files.noAccess')}
                    type={CellTypes.NoAccess}
                />
                : <TableCell
                    content={info.getValue() as string}
                    isContentFilled={isFilled(info.row.original.name)}
                />,
            enableSorting: true,
        },
        {
            id: 'fileType',
            header: t('files.fileType'),
            accessorFn: row => row.file_type?.name ?? null,
            cell: info => (
                <TableCell
                    content={info.getValue() as string ?? null}
                    isContentFilled={isFilled(info.getValue())}
                    type={CellTypes.Tag}
                    color={info.row.original.file_type?.color}
                />
            ),
            enableSorting: false,
        },
        {
            id: 'createdBy',
            header: t('common.createdBy'),
            accessorFn: row => `${row.user.first_name} ${row.user.last_name}`,
            cell: info => (
                <TableCell
                    content={info.row.original.user.first_name}
                    subContent={info.row.original.user.last_name}
                    isContentFilled={isFilled(info.row.original.user.first_name)}
                    type={CellTypes.Name}
                />
            ),
            enableSorting: false,
        },
        {
            id: 'fileCreatedAt',
            header: t('common.createdAt'),
            accessorKey: 'file_created_at',
            cell: info => (
                <TableCell
                    content={info.getValue() as string ?? null}
                    isContentFilled={isFilled(info.getValue())}
                    type={CellTypes.Date}
                />
            ),
            enableSorting: true,
        },
        {
            id: 'createdAt',
            header: t('files.uploadedAt'),
            accessorKey: 'created_at',
            cell: info => (
                <TableCell
                    content={info.getValue() as string ?? null}
                    isContentFilled={isFilled(info.getValue())}
                    type={CellTypes.Date}
                />
            ),
            enableSorting: true,
        },
        {
            id: 'tasks',
            header: t('tasks.task', {count: 2}),
            accessorFn: row => row.tasks?.filter(task => (task.status === TaskStatus.done || task.status === TaskStatus.closed))?.length ?? 0,
            cell: info => (
                <Center gap={2}>
                    <BsFileEarmarkCheckFill
                        fontSize={20}
                        color={`var(--chakra-colors-${getTasksCountColor(info.row.original.tasks)}-300)`}
                    />
                    {`${info.row.original.tasks?.filter(task => (task.status === TaskStatus.done || task.status === TaskStatus.closed))?.length ?? 0} / ${info.row.original.tasks?.length ?? 0}`}
                </Center>
            ),
            enableSorting: false,
        },
        {
            id: 'actions',
            meta: {
                customTd: true,
            },
            cell: info => customAction
                ? customAction(info, ...customActionArgs)
                : isNoAccessFile(info.row.original)
                    ? undefined
                    : (
                        <Td px={0}
                            bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                            <Flex justifyContent={"space-around"}>
                                <Button
                                    height={'25px'}
                                    pe={0}
                                    me={4}
                                    colorScheme={'white'}
                                    isLoading={isLoadingTrigger}
                                    onClick={() => trigger({
                                        id: info.row.original.id,
                                        name: info.row.original.document_name
                                    })}
                                >
                                    <BsFillCloudDownloadFill
                                        fontSize={25}
                                        color="var(--chakra-colors-teal-400)"
                                    />
                                </Button>
                                <Button height={'25px'} ps={0} colorScheme={'white'}
                                        onClick={() => {
                                            if (inModal) {
                                                dispatch(closeFileViewModal())
                                                navigate(`/files/${info.row.original.id}`)
                                            } else {
                                                dispatch(showFileViewModal({file: info.row.original}))
                                            }
                                        }}>
                                    <AiFillEye
                                        fontSize={25}
                                        color="var(--chakra-colors-teal-400)"
                                    />
                                </Button>
                            </Flex>
                        </Td>
                    ),
            enableSorting: false,
        },
    ], [t, inModal, navigate, dispatch, customAction, customActionArgs, isLoadingTrigger, trigger]);


    const defaultVisibility = {created_at: !inModal, tasks: !inModal};

    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(() => {
        return {...defaultVisibility, ...hiddenFields.reduce((acc, field) => ({...acc, [field]: false}), {})};
    });

    return (
        <TableWrapper
            data={files}
            sorting={sorting}
            setSorting={setSorting}
            enableSorting={!inModal}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            columns={columns}
            tableId={TableId.Files}
            noDataMessage={'files.noFiles'}
        />
    );
};
