import React, {useEffect} from "react";
import {
    ButtonGroup,
    Center,
    Container, FormLabel,
    Heading, HStack, IconButton,
    Spacer, useRadioGroup, VStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {BiFilterAlt} from "react-icons/bi";
import {HiFilter, HiSortDescending} from "react-icons/hi";
import {isFileFiltered, selectYearFilter, setYearFilter, showFileFilterModal} from "../../slices/fileFilterModalSlice";
import {isFileSorted, showFileSortModal} from "../../slices/fileSortModalSlice";
import SearchSlideButton from "../SearchSlideButton";
import moment from "moment";
import RadioCard from "../RadioCard";
import TableControls from "../Table/TableControls";
import {TableId} from "../../types";

export default function FilesHeader({count = 0, setSearch = null}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isFiltering = useSelector(isFileFiltered);
    const yearFilter = useSelector(selectYearFilter);
    const isSorting = useSelector(isFileSorted);

    const {
        getRootProps: getRootPropsYear,
        getRadioProps: getRadioPropsYear,
        value: valueYear
    } = useRadioGroup({
        name: "year",
        defaultValue: yearFilter,
    });

    const groupYear = getRootPropsYear();
    const optionsYear = [ moment().year().toString(), (moment().year() - 1).toString(), (moment().year() - 2).toString()];

    useEffect(() => {
        if (valueYear) {
            dispatch(setYearFilter(valueYear));
        }
    }, [valueYear, dispatch]);


    return (
        <Container maxW={"container.xl"} mb={3}>
            <Center w={"full"} pb={2}>
                {/* files header */}
                <Center gap={2}>
                    <Heading fontSize={24}>{t('files.file', {count: 2})}</Heading>
                    <Heading size={"md"} color={"gray.500"}>
                        {count}
                    </Heading>
                </Center>
                <Spacer/>
            </Center>
            <Center>
                <VStack me={4}>
                    <FormLabel alignSelf={'flex-start'} pb={0} mb={0}>{t('common.year')}</FormLabel>
                    <HStack {...groupYear}
                            rounded={"2xl"}
                            bg={"white"}
                            border={"10px primary var(--chakra-colors-teal-200)"}
                    >
                        {optionsYear.map((value) => {
                            const radio = getRadioPropsYear({value});
                            return (
                                <RadioCard key={value} {...radio}>
                                    {value}
                                </RadioCard>
                            );
                        })}
                    </HStack>
                </VStack>
                <Spacer/>

                {/* filter and sort */}
                <ButtonGroup sx={{svg: {fontSize: 20, color: "gray.500"}}}>
                    <SearchSlideButton setSearch={setSearch}/>
                    <IconButton
                        variant={"ghost"}
                        onClick={() => dispatch(showFileSortModal())}
                        aria-label="Sort files"
                        icon={isSorting ? <HiSortDescending color="teal"/> : <HiSortDescending/>}
                    />

                    <IconButton
                        variant={"ghost"}
                        onClick={() => dispatch(showFileFilterModal())}
                        aria-label="Filter files"
                        icon={isFiltering ? <HiFilter color="teal"/> : <BiFilterAlt/>}
                    />
                    <TableControls tableId={TableId.Files}/>
                </ButtonGroup>
            </Center>
        </Container>
    );
}

FilesHeader.propTypes = {
    count: PropTypes.number.isRequired,
};
