import React, {useState} from "react";
import {
    Button,
    Heading,
    Spacer,
    Box,
    Collapse,
    HStack,
} from "@chakra-ui/react";
import {IoMdAddCircleOutline} from "react-icons/io";
import {useTranslation} from "react-i18next";
import FileTypeForm from "./FileTypeForm";

export default function FileTypeCreateSingle() {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(false);

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    return (
        <Box mt={"6"} p={8} bg="white" rounded={"2xl"}>
            <HStack>
                <Heading size={"sm"}>{t('fileType.create.title')}</Heading>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('fileType.create.button')}
                </Button>
            </HStack>

            <Collapse in={isOpen} animateOpacity>
                <FileTypeForm onClose={handleCollapse}/>
            </Collapse>
        </Box>
    );
}
