import {
    Alert, AlertIcon,
    Box,
    Button,
    Center, Container,
    Flex, FormControl, FormErrorMessage, Grid, GridItem,
    Heading, HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightAddon,
    Spacer,
    Text,
} from "@chakra-ui/react";
import React from "react";
import {GoKey} from "react-icons/go";
import {AiFillEye} from "react-icons/ai";
import {useGetCurrentUserQuery, useChangePasswordMutation} from "../services/userService";
import {Field, Form, Formik} from "formik";
import {object, ref, string} from "yup";
import {useTranslation} from "react-i18next";
import FileTypesTable from "../components/Settings/FileType/FileTypesTable";
import PredefinedTaskCreateSingle from "../components/Settings/PredefinedTaskCreateSingle";
import PredefinedTasksTable from "../components/Settings/PredefinedTasksTable";
import {RoleAccess} from "../utils/RoleAccess";
import {Role} from "../constants/roles";
import Usage from "../components/Settings/Usage";
import Subscription from "../components/Settings/Subscription";
import InviteUser from "../components/Settings/InviteUser";
import UsersTable from "../components/Settings/UsersTable";
import {AccessLimit} from "../utils/AccessLimit";
import {AccountType} from "../types";
import InviteClient from "../components/Settings/InviteClient";
import ClientsTable from "../components/Settings/ClientsTable";
import EmailUploadAddressesTable from "../components/Settings/EmailUploadAddressesTable";
import FileTypeCreateSingle from "../components/Settings/FileType/FileTypeCreateSingle";

export default function Settings() {
    const {t} = useTranslation()

    const [changePassword, {isLoading}] = useChangePasswordMutation()
    const {data} = useGetCurrentUserQuery()

    const schema = object({
        old_password: string().min(2).max(50).required(),
        password: string().min(8).max(50).required(),
        confirm_password: string()
            .min(8)
            .max(50)
            .oneOf([ref('password'), null], 'Passwords must match')
            .required()
    });

    return (
        <Container maxW={"container.xl"} p={4}>
            <HStack>
                <Text color={"gray.600"}>{t('nav.account')} /</Text>
                <Heading size={"md"} color={"teal.400"}>
                    {t('nav.setting', {count: 2})}
                </Heading>
            </HStack>
            <RoleAccess allowedRoles={[Role.Manager]}>
                <Usage user={data}/>
            </RoleAccess>
            <RoleAccess allowedRoles={[Role.Manager]}>
                <Subscription user={data}/>
            </RoleAccess>
            <RoleAccess allowedRoles={[Role.Manager]}>
                <UsersTable/>
            </RoleAccess>
            <RoleAccess allowedRoles={[Role.Manager]}>
                <InviteUser user={data}/>
            </RoleAccess>
            <EmailUploadAddressesTable/>
            <AccessLimit allowedRoles={[Role.Manager]} allowedCompanyTypes={[AccountType.agency]}>
                <ClientsTable/>
                <Alert status='info'  borderRadius={"lg"} mt={4}>
                    <AlertIcon />
                    <Text>{t('settings.agencyClientNote')}</Text></Alert>
                <InviteClient/>
            </AccessLimit>
            <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
                <Center mb={2}>
                    <Heading size={"sm"}>{t('settings.changePassword.title')}</Heading>
                    <Spacer/>
                </Center>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        changePassword(values)
                        setSubmitting(false);
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        id: data?.id,
                        old_password: '',
                        password: '',
                        confirm_password: '',
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (<Form>
                        <Flex
                            py={6}
                            flexDirection="column"
                            justifyContent={"center"}
                            gap={1}
                            minW="md"
                        >
                            <Grid templateColumns="repeat(2, 1fr)" columnGap={8} rowGap={4}>
                                <GridItem>
                                    <FormControl isInvalid={!!errors.old_password && touched.old_password}>
                                        <Text fontSize={14} fontWeight={600}>
                                            {t('settings.changePassword.oldPassword')}
                                        </Text>
                                        <InputGroup>
                                            <InputLeftElement pointerEvents="none" children={<GoKey/>}/>
                                            <Input
                                                as={Field}
                                                name={'old_password'}
                                                onBlur={handleBlur}
                                                type="password"
                                                placeholder={t('settings.changePassword.oldPassword')}/>
                                            <InputRightAddon
                                                cursor={"pointer"}
                                                pointerEvents="none"
                                                sx={{}}
                                                children={<AiFillEye/>}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.old_password}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={!!errors.password && touched.password}>
                                        <Text fontSize={14} fontWeight={600}>
                                            {t('settings.changePassword.newPassword')}
                                        </Text>
                                        <InputGroup>
                                            <InputLeftElement pointerEvents="none" children={<GoKey/>}/>
                                            <Input
                                                as={Field}
                                                name={'password'}
                                                onBlur={handleBlur}
                                                type="password"
                                                placeholder={t('settings.changePassword.newPassword')}/>
                                            <InputRightAddon
                                                cursor={"pointer"}
                                                pointerEvents="none"
                                                sx={{}}
                                                children={<AiFillEye/>}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem/>
                                <GridItem>
                                    <FormControl isInvalid={!!errors.confirm_password && touched.confirm_password}>
                                        <Text fontSize={14} fontWeight={600}>
                                            {t('settings.changePassword.confirmPassword')}
                                        </Text>
                                        <InputGroup>

                                            <InputLeftElement pointerEvents="none" children={<GoKey/>}/>
                                            <Input
                                                as={Field}
                                                name={'confirm_password'}
                                                onBlur={handleBlur}
                                                type="password"
                                                placeholder={t('settings.changePassword.confirmPassword')}/>
                                            <InputRightAddon
                                                cursor={"pointer"}
                                                pointerEvents="none"
                                                sx={{}}
                                                children={<AiFillEye/>}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.confirm_password}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                            </Grid>
                            <Spacer/>

                            <HStack pt={10}>
                                <Spacer/>
                                <Button isLoading={isLoading}
                                        loadingText={t('common.actions.saving')}
                                        type={'submit'} variant={"primary"}>{t('common.actions.save')}</Button>
                            </HStack>

                            <Heading
                                size={"sm"}
                                fontWeight={"normal"}
                                textAlign={"center"}
                                mt={1}
                                sx={{a: {color: "teal", fontWeight: "bold"}}}
                            >
                                {/*Do you need an account? <Link>Sign Up</Link>*/}
                                {/*Already have an account? <Link>Login</Link>*/}
                            </Heading>
                        </Flex>
                    </Form>)}
                </Formik>
            </Box>
            <RoleAccess allowedRoles={[Role.User]}>
                <PredefinedTasksTable/>
            </RoleAccess>
            <RoleAccess allowedRoles={[Role.Manager]}>
                <PredefinedTaskCreateSingle/>
            </RoleAccess>
            <RoleAccess allowedRoles={[Role.User]}>
                <FileTypesTable user={data}/>
            </RoleAccess>
            <RoleAccess allowedRoles={[Role.Manager]}>
                <FileTypeCreateSingle user={data}/>
            </RoleAccess>
        </Container>
    );
}
