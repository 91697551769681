import React from "react";
import {useSelector} from "react-redux";
import {Box, Button, ButtonGroup, IconButton, Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import {selectLinks} from "../../slices/dev/devSlice";

const Profiler = () => {
    const links = useSelector(selectLinks)

    return (
        <Box position='fixed' bottom='10' right='10'>
            <Menu>
                <ButtonGroup size='sm' isAttached variant='outline'>
                    {links.length !== 0
                        ? <Button
                            onClick={() => window.open(links[links.length - 1].profiler, "_blank")}
                        >{links[links.length - 1].status} - {links[links.length - 1].url}</Button>
                        : <Button>Nada</Button>
                    }
                    <MenuButton as={IconButton}/> {/* todo add icon */}
                    {/*<IconButton aria-label='Add to friends' icon={<AddIcon />} />*/}
                </ButtonGroup>

                <MenuList>
                    {links.slice(0, -1).map((link, index) =>
                        <MenuItem key={index} onClick={() => window.open(link.profiler, "_blank")}>
                            {link.status} - {link.url}
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>
        </Box>
    )
};

export default (Profiler);
