import {
    Avatar,
    Button,
    ButtonGroup,
    Center,
    Grid,
    GridItem,
    HStack,
    Heading,
    Modal,
    ModalContent,
    ModalOverlay,
    Spacer,
    Stack,
    Text, Divider,
} from "@chakra-ui/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isFileViewOpen, selectFileViewModal, closeFileViewModal} from "../../slices/fileViewModalSlice";
import {showFilePreviewModal} from "../../slices/filePreviewModalSlice";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";
import TaskCreateSingleModal from "../Tasks/TaskCreateSingleModal";
import TasksTable from "../Tasks/TasksTable";
import {isFilled, transformLinkedFilesToUndirected} from "../../utils/generalHelper";
import {useNavigate} from "react-router-dom";
import {IoShareOutline} from "react-icons/io5";
import {copyToast} from "../../slices/toastSlice";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {FileSource, Role} from "../../types";
import {AccessLimit} from "../../utils/AccessLimit";
import {useGetLinkedFilesForFileListQuery} from "../../services/linkedFilesService";
import LinkedFilesTable from "../LinkedFiles/LinkedFilesTable";
import CreateLinkedFilesModal from "../LinkedFiles/CreateLinkedFilesModal";
import {fileSourceIcon} from "../../utils/generalHelperElements";
import EmailUploadView from "../EmailUpload/EmailUploadView";
import CustomTag from "../CustomTag";

export default function FilesView() {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isModalOpen = useSelector(isFileViewOpen)
    const fileViewModal = useSelector(selectFileViewModal)
    const file = fileViewModal.file
    const {
        data: linkedFiles,
        isLoading: isLoadingLinkedFiles
    } = useGetLinkedFilesForFileListQuery({id: file?.id}, {skip: !isModalOpen || file === null})


    const fields = [
        {
            title: 'files.view.originalName',
            field: 'original_name'
        },
        {
            title: 'files.view.internalID',
            field: 'internal_id'
        },
        {
            title: 'files.view.externalID',
            field: 'external_id'
        },
        {
            title: 'common.createdBy',
            field: 'user',
            type: 'profile',
        },
        {
            title: 'common.createdAt',
            field: 'file_created_at',
            type: 'date',
        },
        {
            title: 'files.uploadedAt',
            field: 'created_at',
            type: 'date',
        },
    ];

    return (
        <Modal size={'xl'} isOpen={isModalOpen} onClose={() => dispatch(closeFileViewModal())}>
            <ModalOverlay/>
            <ModalContent p={8} maxW={1000} borderRadius={"2xl"}>
                <Stack gap={4}>
                    <Center mb={4}>
                        <Stack gap={0}>
                            <Heading size={"md"}>{t('files.file', {count: 1})}</Heading>
                            <Text mt={"0 !important"} color={"teal.400"}>
                                {file?.name ?? file?.original_name}
                            </Text>
                        </Stack>
                        <Spacer/>
                        <ButtonGroup>
                            <CopyToClipboard text={`${window.location.origin}/files/${file?.id}`}
                                             onCopy={() => dispatch(copyToast('toast.linkCopy'))}>
                                <Button
                                    bg={"transparent"}
                                    color={"teal.400"}
                                    leftIcon={
                                        <IoShareOutline color="var(--chakra-colors-teal-400)"/>
                                    }
                                >
                                    {t('common.actions.share')}
                                </Button>
                            </CopyToClipboard>
                            <Button
                                bg={"transparent"}
                                color={"teal.400"}
                                onClick={() => dispatch(showFilePreviewModal({
                                    id: file.id,
                                    name: file.document_name
                                }))}
                            >
                                {t('common.actions.preview')}
                            </Button>
                            <AccessLimit allowedRoles={[Role.User]}>
                                <Button w={"auto"} bg={"transparent"} color={"teal.400"} onClick={() => {
                                    dispatch(closeFileViewModal())
                                    navigate(`/files/${file.id}/edit`)
                                }}>
                                    {t('common.actions.edit')}
                                </Button>
                            </AccessLimit>
                        </ButtonGroup>
                    </Center>

                    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                        {fields.map(({title, field, type = null}, index) => {
                            return (
                                <GridItem key={"c" + index}>
                                    <Stack>
                                        <Heading size={"sm"}>{t(title)}</Heading>
                                        <HStack>
                                            {type === 'profile' && <Avatar size={"sm"}
                                                                           name={`${file?.[field]?.first_name} ${file?.[field]?.last_name}`}
                                                                           src=""/>}

                                            <Heading
                                                size={"xs"}
                                                fontWeight={type === 'profile' ? 600 : 400}
                                                // color={file?.[field] !== null ? "black" : "gray.600"}
                                                color={"black"}
                                            >
                                                {type === 'profile'
                                                    ? `${file?.[field]?.first_name} ${file?.[field]?.last_name}`
                                                    : type !== 'date'
                                                        ? file?.[field]
                                                        : isFilled(file?.[field]) ? <Moment format={'MMM D, YYYY'}
                                                                                            locale={i18n.language}>{file?.[field]}</Moment> : null
                                                }
                                                {/*{value.profile || value.data}*/}
                                            </Heading>
                                        </HStack>
                                    </Stack>
                                </GridItem>
                            );
                        })}
                        <GridItem>
                            <Stack>
                                <Heading size={"sm"}>{t('files.fileType')}</Heading>
                                <HStack>
                                    {file?.file_type !== null
                                        ? <CustomTag baseColor={file?.file_type?.color}>
                                            {file?.file_type?.name}
                                        </CustomTag>
                                        : <Heading
                                            size={"xs"}
                                            fontWeight={400}
                                            color={"black"}
                                        >
                                            {t('upload.fileTypeUndefined')}
                                        </Heading>
                                    }
                                </HStack>
                            </Stack>
                        </GridItem>
                        <GridItem>
                            <Stack>
                                <Heading size={"sm"}>{t('files.view.source')}</Heading>
                                <HStack>
                                    {fileSourceIcon(file?.source)}
                                    <Heading
                                        size={"xs"}
                                        fontWeight={400}
                                        color={"black"}
                                    >
                                        {/*TODO MIGRATE TO DISPLAY FIELD*/}
                                        {t(`fileSources.${file?.source}`)}
                                    </Heading>
                                </HStack>
                            </Stack>
                        </GridItem>
                    </Grid>

                    <Stack>
                        <Heading size={"sm"}>{t('common.note', {count: 2})}</Heading>
                        <Text sx={{mt: "4px !important"}}>
                            {file?.note}
                        </Text>
                    </Stack>

                    <AccessLimit allowedRoles={[Role.User]}>
                        <HStack gap={2}>
                            <Heading size={"sm"} color={"gray.400"}>
                                {t('tasks.task', {count: 2})}
                            </Heading>
                            <Divider/>
                        </HStack>
                        <TasksTable tasks={file?.tasks} inModal={true}/>
                        {file?.id && <TaskCreateSingleModal fileId={file?.id}/>}
                    </AccessLimit>
                    <HStack>
                        <Heading size={"sm"} color={"gray.400"} sx={{whiteSpace: "nowrap"}}>
                            {t('linkedFiles.linked', {count: 2})}
                        </Heading>
                        <Divider/>
                    </HStack>
                    {/*todo loading!*/}
                    {file?.id && <LinkedFilesTable
                        linkedFiles={transformLinkedFilesToUndirected(linkedFiles, file.id)}
                        inModal={true}
                    />}
                    {file?.id && <CreateLinkedFilesModal
                        owningFileId={file.id}
                        linkedFiles={linkedFiles}
                        isLoadingLinkedFiles={isLoadingLinkedFiles}
                    />}

                    {file?.source === FileSource.GlobalEmailUpload || file?.source === FileSource.UserEmailUpload
                        ? <AccessLimit allowedRoles={[Role.User]}>
                            <>
                                <HStack gap={2}>
                                    <Heading size={"sm"} color={"gray.400"} whiteSpace="nowrap">
                                        {t('emailUpload.details')}
                                    </Heading>
                                    <Divider/>
                                </HStack>
                                <EmailUploadView uploadHistoryId={file?.upload_history}/>
                            </>
                        </AccessLimit>
                        : undefined
                    }

                    {/*<Stack>*/}
                    {/*    <Heading size={"sm"}>Comments</Heading>*/}
                    {/*    <Stack>*/}
                    {/*        <HStack gap={2}>*/}
                    {/*            <Avatar size={"sm"} name={"a"} src=""/>*/}
                    {/*            <Stack>*/}
                    {/*                <HStack>*/}
                    {/*                    <Heading size={"sm"}>John smith</Heading>*/}
                    {/*                    <Text mt={"0 !important"} fontSize={14} color={"gray.400"}>*/}
                    {/*                        23 mins ago*/}
                    {/*                    </Text>*/}
                    {/*                </HStack>*/}

                    {/*                <Text mt={"0 !important"} fontSize={14}>*/}
                    {/*                    Can you check who paid for this?*/}
                    {/*                </Text>*/}
                    {/*            </Stack>*/}
                    {/*        </HStack>*/}
                    {/*    </Stack>*/}
                    {/*</Stack>*/}

                    {/*<InputGroup>*/}
                    {/*    <Input*/}
                    {/*     placeholder={'Add comment'}*/}
                    {/*    />*/}
                    {/*    <InputRightAddon*/}
                    {/*        cursor={"pointer"}*/}
                    {/*        // borderRadius={0}*/}
                    {/*        children={<IoMdSend color="var(--chakra-colors-teal-400)"/>}*/}
                    {/*    />*/}
                    {/*</InputGroup>*/}

                    <ButtonGroup>
                        <Spacer/>
                        <Button onClick={() => dispatch(closeFileViewModal())}>{t('common.actions.cancel')}</Button>
                        {/*<Button colorScheme="blue" bg={"teal.400"}>*/}
                        {/*    Apply*/}
                        {/*</Button>*/}
                    </ButtonGroup>
                </Stack>
            </ModalContent>
        </Modal>
    );
}
