import PropTypes from 'prop-types';

const {shape, string} = PropTypes;

export const fileType = shape({
    id: string.isRequired,
});

export const taskType = shape({
    id: string.isRequired,
});

export enum SubscriptionStatus {
    trialing = 'trialing',
    active = 'active',
    canceled = 'canceled',
    paused = 'paused',
// past_due
// unpaid
// incomplete
// incomplete_expired
}

export enum Role {
    SuperAdmin = 'ROLE_SUPER_ADMIN',
    Admin = 'ROLE_ADMIN',
    Owner = 'ROLE_OWNER',
    Manager = 'ROLE_MANAGER',
    User = 'ROLE_USER',
    AgencyClient = 'ROLE_AGENCY_CLIENT',
    External = 'ROLE_EXTERNAL'
}

export enum AccountType {
    personal = 'personal',
    company = 'company',
    companyPremium = 'company_premium',
    companyEnterprise = 'company_enterprise',
    agency = 'agency',
    //companies created by agency
    agencyClient = 'agency_client',
    agencyPremium = 'agency_premium',
    agencyEnterprise = 'agency_enterprise',
}

export enum StripePricingLookup {
    personalYearly = 'PERSONAL_YEARLY',
    personalMonthly = 'PERSONAL_MONTHLY',
    businessYearly = 'BUSINESS_YEARLY',
    businessMonthly = 'BUSINESS_MONTHLY',
    extraStorage = 'EXTRA_STORAGE',
    extraCompany = 'EXTRA_COMPANY',
    extraCompanyUser = 'EXTRA_COMPANY_USER',
}

export enum TaskStatus {
    new = 'new',
    open = 'open',
    closed = 'closed',
    done = 'done',
}

export interface Pagination<T> {
    data: T[];
    page: number;
    per_page: number;
    total: number;
    total_pages: number;
}

export interface Subscription {
    id: string;
    status: SubscriptionStatus;
    number_of_seats: number;
    purchased_extra_storage: boolean;
    stripe_customer_id: string;
    stripe_subscription_id: string;
    period_end: string;
}

export interface AddSubscriptionPayload {
    company_id: string;
    status: SubscriptionStatus;
    number_of_seats: number;
    purchased_extra_storage: boolean;
    stripe_customer_id: string;
    stripe_subscription_id: string;
    period_end: string;
}

export interface Company {
    id: string;
    name: string;
    legal_name: string;
    //todo change logo when actually working! it will break CompanyEdit
    logo?: string | null;
    public_email: string;
    account_type: AccountType;
    subscription?: Subscription;
}

export interface UserCompany {
    id: string;
    company: Company;
}

export interface User {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    roles: Role[];
    company: Company;
    user_companies: UserCompany[];
}

export type PaginatedUsers = Pagination<User>;

export type PaginatedCompanies = Pagination<Company>;

export type PaginatedLinkedFiles = Pagination<LinkedFile>;

export type PaginatedUploadHistory = Pagination<UploadHistory>;

export interface UserImpersonation {
    token: string;
    refresh_token: string;
}

export interface RegisterPayload {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    company: RegisterCompanyPayload;
}

export interface RegisterCompanyPayload {
    name: string;
    legal_name: string;
    public_email: string; //for invocices and
    logo: string | null; //later
    account_type: AccountType;
}


export type FileType = {
    id: string
    name: string
    user_access: boolean
    external_access: boolean
    agency_client_access: boolean
    created_at: string
    updated_at: string
    user: User
    color: string|null
}

export type NoAccessFile = {
    id: undefined
    internal_id: FileAccessResponse.AccessDenied
    external_id: FileAccessResponse.AccessDenied
    name: FileAccessResponse.AccessDenied
    document_name: FileAccessResponse.AccessDenied
    tasks: []
    user: User
    file_type: null
    file_created_at: null
    created_at: null
}

export type File = {
    id: string
    internal_id: string | null
    external_id: string | null
    original_name: string
    document_name: string
    name: string | null
    user: User
    note: string
    tasks: Task[]
    file_created_at: string
    file_type: FileType
    count_linked_from: number
    count_linked_to: number
    created_at: string
}

export type LinkedFile = {
    id: string
    linked_to: File;
    linked_from: File;
    note: string;
}

export type EmailUpload = {
    id: string
    sender_email: string
    subject: string
    received_at: string
    receiver_address: {
        id: string
        email: string
        note: string | null
        active: boolean
        external: boolean
        user: any[]
    }
    content_html: string
    content_txt: string
}

export type EmailUploadAddress = {
    id: string
    email: string
    note: string | null
    active: boolean
    external: boolean
    user: {
        id: string
        first_name: string
        last_name: string
        roles: string[]
    } | null
}

export type UploadHistory = {
    id: string
    files: (File|NoAccessFile)[];
    note: string;
    user: User;
    source: FileSource;
    created_at: string
}

export type LinkedFileForm = {
    id?: string
    name: string;
    linked_to: File;
    linked_from?: File;
    note: string;
}


export type LinkedFileUndirected = {
    id: string
    linked: File;
    note: string;
}

export type Task = {
    id: string
    name: string;
    creator: User
    assignee: User
    status: TaskStatus
    days_left: number
}

export type PredefinedTask = {
    id: string
    name: string;
    visibility: PredefinedTaskVisibility;
    event: PredefinedTaskEvent;
    event_id: string | null;
    task_name: string | null;
    task_note: string | null;
    task_assignee: User | null;
    task_days_to_escalate: number | null;
    user: User;
    hidden?: boolean;
}

// export type PaginatedPredefinedTasks = Pagination<PredefinedTask>;

export enum CellTypes {
    Regular = 'regular',
    Date = 'date',
    CombinedIds = 'combined-ids',
    Name = 'name',
    Icon = 'icon',
    NoAccess = 'noAccess',
    Tag = 'tag',
}

export enum FileSource {
    // deprecated in favor of userWebUpload
    UserUpload = 'user_upload',
    UserWebUpload = 'user_web_upload',
    LinkWebUpload = 'link_web_upload',
    UserMobileUpload = 'user_mobile_upload',
    UserEmailUpload = 'user_email_upload',
    GlobalEmailUpload = 'global_email_upload',
}

export enum PredefinedTaskVisibility {
    global = 'global',
}

export enum PredefinedTaskEvent {
    global = 'global',
    all = 'all',
    fileType = 'fileType',
}

export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export type SortValues = {
    sortField: string;
    sortDirection: SortDirection;
}

export interface SearchFormValues {
    search: string | null;
}

export enum InputType { text = 'text', date = 'date', password = 'password'}

export interface FieldConfig {
    title: string;
    field: string;
    type?: FieldType;
}

export enum FieldType {
    Profile = 'profile',
    Date = 'date',
    Source = 'source',
    Text = 'text',
}

export enum FileAccessResponse {
    AccessDenied = "ACCESS_DENIED",
}

export enum TableId {
    Tasks = 'task_table',
    Files = 'files_table',
    LinkedFiles = 'linked_files_table',
    UploadHistory = 'upload_history_table',
}

export const DefaultTagColor = '#319795';
