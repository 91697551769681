import React from "react";
import {
    Button,
    ButtonGroup,
    Center,
    Spacer,
    HStack,
    GridItem,
    Grid,
    FormControl,
    FormErrorMessage,
    Checkbox,
    FormLabel,
    FormHelperText,
    Box,
    VStack,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import {Form, Formik} from "formik";
import {boolean, object, string} from "yup";
import {useTranslation} from "react-i18next";
import {useCreateFileTypeMutation, useEditFileTypeMutation} from "../../../services/fileTypeService";
import AppInput from "../../AppInput";
import {AccessLimit} from "../../../utils/AccessLimit";
import {AccountType, DefaultTagColor, FileType} from "../../../types";
import {transformFormValues} from "../../../utils/generalHelper";
import {HexColorPicker} from "react-colorful";
import {IoColorPaletteOutline} from "react-icons/io5";
import CustomTag from "../../CustomTag";

export default function FileTypeForm(
    {
        onClose,
        fileType = null,
    }:
    {
        onClose: () => void;
        fileType?: FileType | null;
    }) {
    const {t} = useTranslation()
    const [add, {isLoading: isLoadingCreate}] = useCreateFileTypeMutation()
    const [edit, {isLoading: isLoadingEdit}] = useEditFileTypeMutation()

    const schema = object({
        name: string().min(2).max(98).required(),
        hidden: boolean(),
        color: string().matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color').required(),
    });

    return (
        <Formik
            validationSchema={schema}
            validateOnMount={true}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                fileType === null
                    ? add(transformFormValues(values))
                    : edit(transformFormValues({id: fileType.id, ...values}));
                setSubmitting(false);
                resetForm();
                onClose();
            }}
            // enableReinitialize={true}
            initialValues={{
                name: fileType?.name ?? '',
                userAccess: fileType?.user_access ?? true,
                agencyClientAccess: fileType?.agency_client_access ?? true,
                externalAccess: fileType?.external_access ?? false,
                hidden: false,
                color: fileType?.color ?? DefaultTagColor,
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  isSubmitting,
                  errors,
                  setFieldValue,
              }) => (
                <Form>
                    <Grid pt={4} templateColumns="repeat(2, 1fr)" columnGap={8} rowGap={6}>
                        <GridItem>
                            <FormControl isInvalid={!!errors.name && touched.name}>
                                <AppInput
                                    name={`name`}
                                    handleBlur={handleBlur}
                                    label={t('common.name')}
                                    placeholder={t('fileType.fileType', {count: 1})}
                                />
                                <FormErrorMessage>{errors.name}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl as="fieldset">
                                <FormLabel as="legend">
                                    {t('fileType.fileVisibility')}
                                </FormLabel>
                                <HStack spacing="24px">
                                    <Checkbox colorScheme="green" isDisabled defaultChecked>
                                        {t('fileType.visibility.manager')}
                                    </Checkbox>
                                    <Checkbox name={'userAccess'} onChange={handleChange}
                                              isChecked={values.userAccess}
                                              colorScheme='green' defaultChecked>
                                        {t('fileType.visibility.user')}
                                    </Checkbox>
                                    <AccessLimit allowedCompanyTypes={[AccountType.agencyClient]}>
                                        <Checkbox name={'agencyClientAccess'} onChange={handleChange}
                                                  isChecked={values.agencyClientAccess}
                                                  colorScheme='orange' defaultChecked>
                                            {t('users.roles.ROLE_AGENCY_CLIENT')}
                                        </Checkbox>
                                    </AccessLimit>
                                    <Checkbox name={'externalAccess'} onChange={handleChange}
                                              isChecked={values.externalAccess}
                                              colorScheme='red' defaultChecked>
                                        {t('fileType.visibility.external')}
                                    </Checkbox>
                                </HStack>
                                <FormHelperText>{t('fileType.visibility.externalNote')}</FormHelperText>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>{t('fileType.color')}</FormLabel>
                                <HStack alignItems="flex-start" spacing={4}>
                                    <VStack alignItems="flex-start" spacing={2}>
                                        <HexColorPicker
                                            color={values.color}
                                            onChange={(color) => setFieldValue('color', color)}
                                        />
                                    </VStack>
                                    <VStack alignItems="flex-start" spacing={2}>
                                        <Box
                                            w="40px"
                                            h="40px"
                                            borderRadius="md"
                                            bg={values.color}
                                            border="1px solid"
                                            borderColor="gray.200"
                                        />
                                        <Text fontSize="sm">{values.color}</Text>
                                        <Tooltip label={t('common.actions.resetToDefault')}>
                                            <Button
                                                size="sm"
                                                onClick={() => setFieldValue('color', fileType?.color ?? DefaultTagColor)}
                                                leftIcon={<IoColorPaletteOutline/>}
                                            >
                                                {t('common.actions.reset')}
                                            </Button>
                                        </Tooltip>
                                    </VStack>
                                </HStack>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel>{t('common.actions.preview')}</FormLabel>
                                <Box
                                    borderWidth={1}
                                    borderRadius="md"
                                    p={4}
                                    bg="gray.50"
                                    boxShadow="sm"
                                >
                                    <CustomTag baseColor={values.color} withTransparency={true}>
                                        {values.name || t('fileType.preview')}
                                    </CustomTag>
                                </Box>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Center
                        mt={16}
                        sx={{
                            button: {
                                minW: 160,
                            },
                        }}
                    >
                        <Spacer/>
                        <ButtonGroup gap={3}>
                            <Button
                                isLoading={isLoadingCreate || isLoadingEdit}
                                loadingText={t('common.actions.saving')} type={'submit'}
                                variant={"primary"}>{t('common.actions.save')}</Button>
                        </ButtonGroup>
                    </Center>
                </Form>
            )}
        </Formik>
    );
}
