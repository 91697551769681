import {
    Button,
    Center,
    Flex, Td,
} from "@chakra-ui/react";
import React, {useMemo} from "react";
import {BsFileEarmarkCheckFill} from "react-icons/bs";
import {CellTypes, TableId, Task} from "../../types";
import {useTranslation} from "react-i18next";
import {AiFillEye} from "react-icons/ai";
import {closeTaskViewModal, showTaskViewModal} from "../../slices/taskViewModalSlice";
import {getEscalationColour, getTaskColour} from "../../utils/taskHelper";
import {useNavigate} from "react-router-dom";
import {isFilled} from "../../utils/generalHelper";
import {selectTaskRawSorting, setSorting} from "../../slices/taskSortModalSlice";
import {ColumnDef, VisibilityState} from "@tanstack/react-table";
import {useAppDispatch, useAppSelector} from "../../hooks/store";
import TableWrapper from "../Table/TableWrapper";
import TableCell from "../Table/TableCell";

export default function TasksTableNew({tasks = [], inModal = false}: {
    tasks: Task[], inModal?: boolean
}) {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const sorting = useAppSelector(selectTaskRawSorting)

    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(
        {created_at: !inModal, tasks: !inModal}
    )

    const columns = useMemo<ColumnDef<Task>[]>(() => [
        {
            id: 'name',
            accessorKey: 'name',
            // meta: {
            //     customTd: true,
            // },
            header: t('tasks.name'),
            size: 250,
            enableSorting: true,
            cell: info => (
                <TableCell
                    content={info.getValue() as string}
                    isContentFilled={isFilled(info.row.original.name)}
                />
            ),

        },
        {
            id: 'createdBy',
            header: t('common.createdBy'),
            size: 120,
            enableSorting: false,
            accessorFn: row => `${row.creator.first_name} ${row.creator.last_name}`,
            cell: info => (
                <TableCell
                    content={info.row.original.creator.first_name}
                    subContent={info.row.original.creator.last_name}
                    isContentFilled={isFilled(info.row.original.creator.first_name)}
                    type={CellTypes.Name}
                />
            ),
        },
        {
            id: 'assignee',
            header: t('common.assignee'),
            size: 120,
            enableSorting: false,
            accessorFn: row => `${row.assignee.first_name} ${row.assignee.last_name}`,
            cell: info => (
                <TableCell
                    content={info.row.original.assignee.first_name}
                    subContent={info.row.original.assignee.last_name}
                    isContentFilled={isFilled(info.row.original.assignee.first_name)}
                    // shorten={true}
                    type={CellTypes.Name}
                />
            ),
        },
        {
            id: 'status',
            accessorKey: 'status',
            header: t('common.status'),
            size: 80,
            enableSorting: false,
            cell: info => (
                <TableCell
                    content={t(`tasks.status.${info.row.original.status}`).toUpperCase()}
                    type={CellTypes.Tag}
                    color={getTaskColour(info.row.original.status)}
                />
            ),
        },
        {
            id: 'daysLeft',
            accessorKey: 'days_left',
            header: t('tasks.timeLeft'),
            size: 80,
            cell: info => (
                <Center gap={2}>
                    <BsFileEarmarkCheckFill
                        fontSize={20}
                        color={`var(--chakra-colors-${getEscalationColour(info.row.original.days_left, info.row.original.status)}-400)`}
                    />
                    {(info.row.original.status !== 'done' && info.row.original.status !== 'closed') && `${info.row.original.days_left} d`}
                </Center>
            ),
        },
        {
            id: 'actions',
            size: 20,
            enableResizing: false,
            enableSorting: false,
            meta: {
                customTd: true,
            },
            cell: info => (
                <Td px={0}
                    bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                    <Flex justifyContent="space-around">
                        <Button height="25px" colorScheme="white"
                                onClick={() => {
                                    if (inModal) {
                                        dispatch(closeTaskViewModal())
                                        navigate(`/tasks/${info.row.original.id}`)
                                    } else {
                                        dispatch(showTaskViewModal({task: info.row.original}))
                                    }
                                }}>
                            <AiFillEye
                                fontSize={25}
                                color="var(--chakra-colors-teal-400)"
                            />
                        </Button>
                    </Flex>
                </Td>
            ),
        },
    ], [t, inModal, navigate, dispatch]);

    return (
        <TableWrapper
            data={tasks}
            sorting={sorting}
            setSorting={setSorting}
            enableSorting={!inModal}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            columns={columns}
            tableId={TableId.Tasks}
            noDataMessage={'tasks.noTasks'}
        />
    );
}
