import {useTranslation} from "react-i18next";
import React, {ReactNode} from "react";
import {isFilled} from "../../utils/generalHelper";
import {
    Center,
    HStack,
    Stack,
    Text,
} from "@chakra-ui/react";
import Moment from "react-moment";
import {BsFillFileEarmarkXFill} from "react-icons/bs";
import {CellTypes} from "../../types";
import {IoHandLeftOutline} from "react-icons/io5";
import TableCellText from "../../components/Table/TableCellText";
import CustomTag from "../CustomTag";


export default function TableCell(
    {
        content,
        subContent,
        placeholder = '',
        maxLength = 10,
        isContentFilled = true,
        color = null,
        type = CellTypes.Regular,
    }:
    {
        content: string | null
        subContent?: string | null
        placeholder?: string
        maxLength?: number
        isContentFilled?: boolean
        color?: string | null
        type?: CellTypes
    }
) {
    const {t, i18n} = useTranslation();


    const cell = (): ReactNode | string | null => {
        switch (type) {
            case CellTypes.Regular:
                if (!isFilled(content)) {
                    return '';
                }
                return <TableCellText text={content} tooltipLabel={content}/>
            case CellTypes.Date: {
                if (isFilled(content)) {
                    let date = <Moment format={'MMM D, YYYY'} locale={i18n.language}>{content}</Moment>

                    return <TableCellText text={date} tooltipLabel={date}/>
                }

                return null;
            }
            case CellTypes.CombinedIds: {
                const contentPart = isFilled(content) ? content : ' - ';
                const subContentPart = isFilled(subContent) ? subContent : ' - ';
                let text = `${contentPart} / ${subContentPart}`;

                return isContentFilled
                    ?
                    <TableCellText
                        text={text}
                        tooltipLabel={
                            <Stack>
                                <Text>{`${t('files.view.internalID')}: ${content ?? ''}`}</Text>
                                <Text>{`${t('files.view.externalID')}: ${subContent ?? ''}`}</Text>
                            </Stack>
                        }
                    />
                    : <TableCellText text={placeholder} tooltipLabel={placeholder}/>

            }
            case CellTypes.Name: {
                let name = `${content ?? ''} ${subContent ?? ''}`
                return isContentFilled
                    ? <TableCellText text={content ?? ''} tooltipLabel={name}/>
                    : null
            }
            case CellTypes.NoAccess: {
                if (!isFilled(content)) {
                    return '';
                }

                return <TableCellText
                    text={
                        <HStack>
                            <IoHandLeftOutline color="var(--chakra-colors-red-500)"/>
                            <Text>{content.substring(0, maxLength) + '...'}</Text>
                        </HStack>
                    }
                    tooltipLabel={content}
                />
            }
            case CellTypes.Tag: {
                if (!isFilled(content)) {
                    return '';
                }

                return (
                    <CustomTag baseColor={color} withTransparency={true}>
                        <TableCellText
                            text={content}
                            tooltipLabel={content}
                        />
                    </CustomTag>
                )
            }
            default:
                if (!isFilled(content)) {
                    return '';
                }

                return <TableCellText text={content} tooltipLabel={content}/>
        }
    };

    return (
        <Center gap={2}>
            {!isContentFilled &&
                <BsFillFileEarmarkXFill
                    color={'var(--chakra-colors-red-400)'}
                    fontSize={20}
                    // color="var(--chakra-colors-teal-400)"
                />
            }
            {cell()}
        </Center>
    );
};
