import React from "react";
import {Tag} from "@chakra-ui/react";
import {DefaultTagColor} from "../types";

interface CustomTagProps {
    baseColor: string | null;
    colour?: string | null;
    children: React.ReactNode;
    withTransparency?: boolean;
    [key: string]: any;
}

const CustomTag: React.FC<CustomTagProps> = ({baseColor, colour = null, children, withTransparency = false, ...props}) => {
    baseColor = baseColor ?? DefaultTagColor

    const isHexBaseColor = baseColor.startsWith("#");

    let bgColor = isHexBaseColor ? baseColor : baseColor + ".800"
    let textColor = colour ?? '#fff'

    if (withTransparency) {
        bgColor = isHexBaseColor ? baseColor + "35" : baseColor + ".100"
        textColor = isHexBaseColor ? baseColor : baseColor + ".800"
    }

    return (
        <Tag
            py={2}
            bg={bgColor}
            color={textColor}
            justifyContent={"center"}
            {...props}
        >
            {children}
        </Tag>
    );
};

export default CustomTag;
