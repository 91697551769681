import React from 'react'
import {
    Tbody,
    Td,
    Tr,
    Text,
} from "@chakra-ui/react";
import {
    flexRender,
    Table as TanstackTable,
} from '@tanstack/react-table'
import {useTranslation} from "react-i18next";

export default function TableBody(
    {
        table,
        noDataMessage = null,
    }: {
        table: TanstackTable<any>,
        noDataMessage?: string | null,
    }) {
    const {t} = useTranslation();

    return (
        <Tbody
            sx={{
                td: {color: "gray.700", textAlign: "center"},
                "tr:nth-of-type(odd) td": {bg: "gray.50"},
            }}
        >
            {table.getRowModel().rows.map(row => (
                <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                        //todo fix correct as any
                        const meta = (cell.column.columnDef as any).meta;
                        if (meta?.customTd === true) {
                            return flexRender(cell.column.columnDef.cell, cell.getContext())
                        }

                        return (
                            <Td
                                key={cell.id}
                                sx={{
                                    width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
                                    maxWidth: `calc(var(--col-${cell.column.id}-size) * 1px)`,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                {...(meta ? meta.customStyles : {})}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        )
                    })}
                </Tr>
            ))}
            {table.getRowModel().rows.length === 0 && noDataMessage !== null
                ? <Tr>
                    <Td colSpan={table.getHeaderGroups().map(obj => obj.headers).flat().length}>
                        <Text textAlign="center" py={5}>{t(noDataMessage)}</Text>
                    </Td>
                </Tr>
                : undefined
            }
        </Tbody>
    )
}
