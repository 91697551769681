import React, {useState} from "react";
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import {AccountType, CellTypes, DefaultTagColor, FileType, Role} from "../../../types";
import {isFilled} from "../../../utils/generalHelper";
import {useTranslation} from "react-i18next";
import {useGetFileTypesListQuery} from "../../../services/fileTypeService";
import {IoCheckmarkCircleSharp, IoHandLeft, IoPencil} from "react-icons/io5";
import {AccessLimit} from "../../../utils/AccessLimit";
import FileTypeEdit from "./FileTypeEdit";
import TableCell from "../../Table/TableCell";

export default function FileTypesTable() {
    const {data: fileTypes} = useGetFileTypesListQuery()
    const {t} = useTranslation()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [fileType, setFileType] = useState<FileType | null>(null)

    return (
        <Box
            bg={"white"}
            rounded={"2xl"}
            border={"1px primary var(--chakra-colors-gray-200)"}
            mt={"6"}
        >
            <Center p={8} mt={4} mb={2}>
                <Heading size={"sm"}>{t('fileType.fileType', {count: 2})}</Heading>
                <Spacer/>
            </Center>
            <TableContainer w={"full"}>
                <Table variant="striped">
                    <Thead>
                        <Tr sx={{th: {color: "black", textAlign: "center"}}}>
                            <Th>{t('common.name')}</Th>
                            <Th>{t('fileType.visibility.user')}</Th>
                            <AccessLimit allowedCompanyTypes={[AccountType.agencyClient]}>
                                <Th>{t('users.roles.ROLE_AGENCY_CLIENT')}</Th>
                            </AccessLimit>
                            <Th>{t('fileType.visibility.external')}</Th>
                            <Th>{t('common.createdBy')} </Th>
                            <Th>{t('common.createdAt')} </Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody
                        sx={{
                            td: {color: "gray.700", textAlign: "center"},
                            "tr:nth-of-type(odd) td": {bg: "gray.50"},
                        }}
                    >
                        {fileTypes?.map((fileType, i) => {
                            return (
                                <Tr key={"b" + i}>
                                    <Td>
                                        <TableCell
                                            content={fileType.name}
                                            isContentFilled={isFilled(fileType.name)}
                                            type={CellTypes.Tag}
                                            color={fileType.color ?? DefaultTagColor}
                                        />
                                    </Td>
                                    <Td>
                                        <Center gap={2}>
                                            {fileType.user_access
                                                ? <IoCheckmarkCircleSharp
                                                    fontSize={20}
                                                    color={`var(--chakra-colors-green-300)`}
                                                />
                                                : <IoHandLeft color={`var(--chakra-colors-red-300)`}
                                                />}
                                        </Center>
                                    </Td>
                                    <AccessLimit allowedCompanyTypes={[AccountType.agencyClient]}>
                                        <Td>
                                            <Center gap={2}>
                                                {fileType.agency_client_access
                                                    ? <IoCheckmarkCircleSharp
                                                        fontSize={20}
                                                        color={`var(--chakra-colors-green-300)`}
                                                    />
                                                    : <IoHandLeft color={`var(--chakra-colors-red-300)`}
                                                    />}
                                            </Center>
                                        </Td>
                                    </AccessLimit>
                                    <Td>
                                        <Center gap={2}>
                                            {fileType.external_access
                                                ? <IoCheckmarkCircleSharp
                                                    fontSize={20}
                                                    color={`var(--chakra-colors-green-300)`}
                                                />
                                                : <IoHandLeft color={`var(--chakra-colors-red-300)`}
                                                />}
                                        </Center>
                                    </Td>
                                    <Td>
                                        <TableCell
                                            content={fileType?.user?.first_name}
                                            subContent={fileType?.user?.last_name}
                                            isContentFilled={isFilled(fileType?.user?.first_name)}
                                            type={CellTypes.Name}
                                        />
                                    </Td>
                                    <Td>
                                        <TableCell
                                            content={fileType.created_at}
                                            type={CellTypes.Date}
                                        />
                                    </Td>
                                    <Td
                                        px={0}
                                        bg={i % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}
                                    >
                                        <Flex justifyContent={"space-around"}>
                                            <AccessLimit allowedRoles={[Role.Manager]}>
                                                <Button height={'25px'} ps={0} colorScheme={'white'}
                                                        onClick={() => {
                                                            setFileType(fileType)
                                                            onOpen()
                                                            // dispatch(showFileViewModal({file: file}))
                                                        }}>
                                                    <IoPencil
                                                        fontSize={25}
                                                        color="var(--chakra-colors-teal-400)"
                                                    />
                                                </Button>
                                            </AccessLimit>
                                        </Flex>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            <FileTypeEdit isOpen={isOpen} onClose={onClose} fileType={fileType}/>
        </Box>
    );
}
