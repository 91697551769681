import React from 'react'
import {IconButton} from "@chakra-ui/react";
import {resetColumnWidths,} from "../../slices/tableColumnWidthsSlice";
import {useAppDispatch} from "../../hooks/store";
import {AiOutlineColumnWidth} from "react-icons/ai";

export default function TableControls({tableId}: { tableId: string, }) {
    const dispatch = useAppDispatch()

    return (
        <IconButton
            variant={"ghost"}
            onClick={() => dispatch(resetColumnWidths({tableId}))}
            aria-label="Reset collumn width"
            icon={<AiOutlineColumnWidth color="teal"/>}
            // icon={isSorting ? <AiOutlineColumnWidth color="teal"/> : <AiOutlineColumnWidth/>}
        />
    )
}

