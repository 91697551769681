import {baseApi} from "./apiService";
import {FileType} from "../types";

export const fileTypeApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getFileTypesList: builder.query<FileType[], void>({
            query: (page) => ({
                url: 'file_types',
                params: {page: page},
            }),
            providesTags: (result: FileType[] | undefined) =>
                result
                    ? [...result.map(({id}) => ({type: 'FileTypes' as const, id})), 'FileTypes']
                    : ['FileTypes'],
        }),
        createFileType: builder.mutation({
            query: (body) => ({
                url: `file_types`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['FileTypes'],
        }),
        editFileType: builder.mutation({
            query: ({id, ...body}) => ({
                url: `file_types/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['FileTypes'],
        }),
    })
});

export const {
    useCreateFileTypeMutation,
    useEditFileTypeMutation,
    useGetFileTypesListQuery,
} = fileTypeApi
