import {
    Box,
    Button,
    HStack,
    Heading,
    Modal,
    ModalContent,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {FileType} from "../../../types";
import FileTypeForm from "./FileTypeForm";

export default function FileTypeEdit(
    {
        isOpen,
        onClose,
        fileType,
    }:
    {
        isOpen: boolean,
        onClose: () => void;
        fileType: FileType | null;
    }) {
    const {t} = useTranslation()

    return (
        <Modal isOpen={isOpen} onClose={() => onClose()}>
            <ModalContent minW={700} maxW={1000} p={8} rounded={"md"}>
                <Box>
                    <Stack mb={4}>
                        <Heading size="md">{t('fileType.edit.title')}</Heading>
                        <Text sx={{mt: "0 !important"}} color={"teal.400"}>
                            {fileType?.name}
                        </Text>
                    </Stack>

                    <FileTypeForm onClose={onClose} fileType={fileType}/>
                    <HStack mt={6}>
                        <Spacer/>
                        <Button variant={"gray"} onClick={() => onClose()}>
                            {t('common.actions.cancel')}
                        </Button>
                    </HStack>
                </Box>
            </ModalContent>
        </Modal>
    );
}

