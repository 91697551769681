import React, {ReactNode} from "react";
import {
    Text,
    Tooltip,
} from "@chakra-ui/react";

export default function TableCellText(
    {
        text,
        tooltipLabel,
    }:
    {
        text: ReactNode | string | null
        tooltipLabel?: ReactNode | string | null
    }
) {
    return (
        <Tooltip label={tooltipLabel} placement="top" hasArrow>
            <Text
                isTruncated
                maxWidth="100%" // Adjusts dynamically to table cell width
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
            >
                {text}
            </Text>
        </Tooltip>
    );
}

